export const LISTA_FORMA_PROC_PAGO = [
    {
        i_for_pro_pa: 1,
        d_des_for_pra: 'POST'
    },
    {
        i_for_pro_pa: 1,
        d_des_for_pra: 'Pago Electrónico'
    }
];
