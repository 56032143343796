import { useHttpClient } from '../../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { Tag } from 'primereact/tag';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import CambiarClave from '../../components/CambiarClave';

const Usuario = (props) => {
    const httpClient = useHttpClient();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [filters] = useState(null);
    const [trigger, setTrigger] = useState(true);
    const [loading, setLoading] = useState(true);
    const [displayDialog, setDisplayDialog] = useState(false);

    const toast = useRef(null);
    const usernameRef = useRef('');

    useEffect(() => {
        if (isEmpty(users) || trigger) {
            httpClient
                .get('admin/usuario/all')
                .then((response) => {
                    setUsers(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
            setTrigger(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, trigger]);

    const canCreateOrUpdate = useMemo(() => {
        return props.role === 'root';
    }, [props.role]);

    const createUser = () => {
        navigate('/administracion/usuarios/usuario');
    };

    const editUser = (nombreUsuario) => {
        navigate(`/administracion/usuarios/usuario?nombreUsuario=${nombreUsuario}`);
    };

    const accept = () => {
        httpClient
            .post(`admin/usuario/cambiarEstado/${usernameRef.current}`, {})
            .then((response) => {
                setTrigger(true);
                toast.current.show({ severity: 'success', summary: 'Operación realizada', detail: `Estado del usuario ${usernameRef.current} actualizado`, life: 10000 });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: `Error al actualizar el usuario ${usernameRef.current}`, life: 10000 });
            });
    };

    const reject = () => {};

    const confirm = () => {
        confirmDialog({
            message: '¿Está seguro de realizar esta acción?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Si',
            accept,
            reject
        });
    };

    const header = () => {
        if (canCreateOrUpdate) {
            return (
                <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span className="text-xl text-900 font-bold">Usuarios</span>
                    <Button icon="pi pi-plus" onClick={() => createUser()} />
                </div>
            );
        }

        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">Usuarios</span>
            </div>
        );
    };

    const formatCreatedDate = (row) => {
        const date = Date.parse(row.fecha_creacion);
        return format(date, 'dd-MM-yyyy HH:mm:ss');
    };

    const activeUserBodyTemplate = (rowData) => {
        return <Tag value={getActiveText(rowData.activado)} severity={isActiveSeverity(rowData.activado)}></Tag>;
    };

    const getActiveText = (isActive) => {
        if (isActive) return 'Activo';

        return 'Inactivo';
    };

    const isActiveSeverity = (isActive) => {
        if (isActive) return 'success';

        return 'danger';
    };

    const onPasswordChangeSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Operación realizada', detail: `Clave del usuario ${usernameRef.current} actualizada`, life: 10000 });
        setDisplayDialog(false);
    };

    const actionTemplate = (rowData) => {
        let icon = 'pi pi-ban';
        let classes = 'p-button-rounded p-button-danger p-button-text mr-2 mb-2';
        let tooltip = 'Inhabilitar usuario';
        if (!rowData.activado) {
            icon = 'pi pi-check';
            classes = 'p-button-rounded p-button-success p-button-text mr-2 mb-2';
            tooltip = 'Habilitar usuario';
        }

        if (canCreateOrUpdate) {
            return (
                <div className="flex flex-wrap justify-content-center">
                    <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-info p-button-text mr-2 mb-2" tooltip="Modificar usuario" onClick={() => editUser(rowData.nombre_usuario)} />
                    <Button
                        type="button"
                        icon="pi pi-key"
                        className="p-button-rounded p-button-warn p-button-text mr-2 mb-2"
                        tooltip="Cambiar Clave"
                        onClick={() => {
                            usernameRef.current = rowData.nombre_usuario;
                            setDisplayDialog(true);
                        }}
                    />
                    <Button
                        type="button"
                        icon={icon}
                        className={classes}
                        tooltip={tooltip}
                        onClick={() => {
                            usernameRef.current = rowData.nombre_usuario;
                            confirm();
                        }}
                    />
                </div>
            );
        }
        return (
            <div className="flex flex-wrap justify-content-center">
                <Button
                    type="button"
                    icon="pi pi-key"
                    className="p-button-rounded p-button-warn p-button-text mr-2 mb-2"
                    tooltip="Cambiar Clave"
                    onClick={() => {
                        usernameRef.current = rowData.nombre_usuario;
                        setDisplayDialog(true);
                    }}
                />
                <Button
                    type="button"
                    icon={icon}
                    className={classes}
                    tooltip={tooltip}
                    onClick={() => {
                        usernameRef.current = rowData.nombre_usuario;
                        confirm();
                    }}
                />
            </div>
        );
    };

    return (
        <div className="grid table-demo">
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="col-12">
                <div className="card">
                    <DataTable value={users} header={header} className="mt-3" dataKey="nombre_usuario" filters={filters} filterDisplay="row" loading={loading} stripedRows responsiveLayout="stack" emptyMessage="No existen usuarios">
                        <Column field="nombre_usuario" header="Nombre de usuario" sortable filter filterPlaceholder="Nombre de usuario" style={{ maxWidth: '12rem' }} />
                        <Column field="rol" header="Rol" sortable filter filterPlaceholder="Rol" style={{ maxWidth: '12rem' }} />
                        <Column field="fecha_creacion" header="Fecha de creación" sortable filter filterPlaceholder="Fecha de creación" style={{ maxWidth: '12rem' }} body={formatCreatedDate} />
                        <Column field="activado" header="Estado" sortable style={{ minWidth: '1rem' }} body={activeUserBodyTemplate} />
                        <Column header="Acciones" style={{ flex: '0 0 3rem', minWidth: '3rem' }} body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog
                header="Actualizar Clave"
                visible={displayDialog}
                style={{ minWidth: '18vw' }}
                modal
                // footer={basicDialogFooter}
                onHide={() => setDisplayDialog(false)}
            >
                <CambiarClave nombreUsuario={usernameRef.current} onSuccess={() => onPasswordChangeSuccess()} />
            </Dialog>
        </div>
    );
};

export default Usuario;
