import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { AFECTACION_TRIBUTARIA_EXENTO, AFECTACION_TRIBUTARIA_EXONERADO } from '../../../utilities/constants';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { useMountEffect } from 'primereact/hooks';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import DescargarKuDE from '../../../components/DescargarKuDE';
import { Toast } from 'primereact/toast';
import EnviarAssets from '../../../components/EnviarAssets';
import CancelarDocumentosElectronicos from '../../../components/CancelarDocumentosElectronicos';
import XMLInspector from '../../../components/XMLInspector';
import { useSelector } from 'react-redux';
import DescargarXML from '../../../components/DescargarXML';
import AnularDocumentoElectronico from '../../../components/AnularDocumentoElectronico';
import { ConfirmDialog } from 'primereact/confirmdialog';

const DetalleDocumentoElectronico = () => {
    const [deType, setDeType] = useState('');
    const [edoc, setEdoc] = useState({});
    const [emailEvents, setEmailEvents] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [isloading, setIsLoading] = useState(false);
    const [isLoadingEmailEvents, setIsLoadingEmailEvents] = useState(false);
    const httpClient = useHttpClient();
    const toast = useRef(null);
    const role = useSelector((state) => state.session.rol);

    const { cdc } = useParams();

    const navigate = useNavigate();

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const guarani = new Intl.NumberFormat('es-PY', {
        style: 'currency',
        currency: 'PYG',
        maximumFractionDigits: 0
    });

    const usdDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    });

    useMountEffect(() => {
        loadEdoc();
    });

    const loadEdoc = () => {
        setIsLoading(true);
        httpClient
            .get(`api/de/${cdc}`)
            .then((response) => {
                setEdoc(response.data);
                setDeType(response.data.g_timb.d_des_ti_de);
                setLineItems(response.data.g_d_tip_de.g_cam_item);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                navigate('/administracion/documentos');
            });
    };

    useEffect(() => {
        if (!isEmpty(edoc?.email_id)) {
            setIsLoadingEmailEvents(true);
            httpClient
                .get(`admin/email/eventos/${cdc}`)
                .then((response) => {
                    setEmailEvents(response.data);
                    setIsLoadingEmailEvents(false);
                })
                .catch((error) => {
                    setIsLoadingEmailEvents(false);
                    navigate('/administracion/documentos');
                });
        }
        // eslint-disable-next-line
    }, [edoc?.email_id]);

    const marshallRUC = () => {
        if (isEmpty(edoc)) return '';
        return `${edoc.g_dat_gral_ope.g_emis.d_ruc_em}-${edoc.g_dat_gral_ope.g_emis.d_dv_emi}`;
    };
    const marshallEdocNumber = () => {
        if (isEmpty(edoc)) return '';
        return `${edoc.g_timb.d_est}-${edoc.g_timb.d_pun_exp}-${edoc.g_timb.d_num_doc}`;
    };

    // const issuerHeaderTemplate = (options) => {
    //     return <div className="flex align-items-center justify-content-center gap-1 pt-2 py-2">{isloading ? <Skeleton height="2rem" width="13rem" className="mb-2"></Skeleton> : <h5>{deType}</h5>}</div>;
    // };

    // const receiverHeaderTemplate = (options) => {
    //     return (
    //         <div className="flex align-items-center justify-content-center gap-1 pt-2 py-2">
    //             <h5>Datos del receptor</h5>
    //         </div>
    //     );
    // };

    const formatIssueDate = () => {
        if (isEmpty(edoc)) return '';
        return formatDate(edoc.g_dat_gral_ope.d_fe_emi_de);
    };

    const formatDate = (input) => {
        if (isEmpty(input)) return '';
        const date = Date.parse(input);
        return format(date, 'dd-MM-yyyy HH:mm:ss');
    };

    const formatDestRUC = () => {
        if (isEmpty(edoc)) return '';

        //Contribuyente
        if (edoc.g_dat_gral_ope.g_dat_rec.i_nat_rec === 1) {
            return `${edoc.g_dat_gral_ope.g_dat_rec.d_ruc_rec}-${edoc.g_dat_gral_ope.g_dat_rec.d_dv_rec}`;
        }

        return `${edoc.g_dat_gral_ope.g_dat_rec.d_num_id_rec}`;
    };

    const exentasVal = (item) => {
        let val = 0;
        if (item.g_cam_iva.i_afec_iva === AFECTACION_TRIBUTARIA_EXONERADO || item.g_cam_iva.i_afec_iva === AFECTACION_TRIBUTARIA_EXENTO) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }

        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(val);
        }

        return guarani.format(val);
    };

    const unitPriceVal = (item) => {
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(item.g_valor_item.d_p_uni_pro_ser);
        }
        return guarani.format(item.g_valor_item.d_p_uni_pro_ser);
    };

    const val5 = (item) => {
        let val = 0;
        if (item.g_cam_iva.d_tasa_iva === 5) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(val);
        }
        return guarani.format(val);
    };

    const val10 = (item) => {
        let val = 0;
        if (item.g_cam_iva.d_tasa_iva === 10) {
            val = item.g_valor_item.g_valor_resta_item.d_tot_ope_item;
        }

        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(val);
        }
        return guarani.format(val);
    };

    const valTotExentas = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc.g_tot_sub.d_sub_exe);
        }
        return guarani.format(edoc.g_tot_sub.d_sub_exe);
    };

    const valTot5IVA = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc.g_tot_sub.d_sub_5);
        }
        return guarani.format(edoc.g_tot_sub.d_sub_5);
    };
    const valTot10IVA = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc?.g_tot_sub?.d_sub_10);
        }
        return guarani.format(edoc?.g_tot_sub?.d_sub_10);
    };
    const valTotGralOpe = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc?.g_tot_sub?.d_tot_gral_ope);
        }
        return guarani.format(edoc?.g_tot_sub?.d_tot_gral_ope);
    };
    const valLiqIVA5 = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc?.g_tot_sub?.d_iva_5);
        }
        return guarani.format(edoc?.g_tot_sub?.d_iva_5);
    };
    const valLiqIVA10 = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc?.g_tot_sub?.d_iva_10);
        }
        return guarani.format(edoc?.g_tot_sub?.d_iva_10);
    };

    const valTotIVA = () => {
        if (isEmpty(edoc)) return 0;
        if (edoc.g_dat_gral_ope?.g_ope_com?.c_mone_ope === 'USD') {
            return usdDollar.format(edoc?.g_tot_sub?.d_tot_iva);
        }
        return guarani.format(edoc?.g_tot_sub?.d_tot_iva);
    };

    const msgSIFENResponse = () => {
        return edoc?.respuesta_sifen?.msg_resultado ? edoc?.respuesta_sifen?.msg_resultado : edoc.error;
    };

    const headerGroup = (
        <ColumnGroup>
            {edoc.g_timb?.i_ti_de !== 7 ? (
                <Row>
                    <Column header="Cod" field="d_cod_int" />
                    <Column header="Descripción" field="d_des_pro_ser" />
                    {/*<Column header="Unidad de medida" field="d_des_uni_med" />*/}
                    <Column header="Cantidad" field="d_cant_pro_ser" />

                    <Column header="Precio unitario" />
                    <Column header="Exentas" />
                    <Column header="5%" />
                    <Column header="10%" />
                </Row>
            ) : (
                <Row>
                    <Column header="Cod" field="d_cod_int" />
                    <Column header="Descripción" field="d_des_pro_ser" />
                    {/*<Column header="Unidad de medida" field="d_des_uni_med" />*/}
                    <Column header="Cantidad" field="d_cant_pro_ser" />
                </Row>
            )}
        </ColumnGroup>
    );

    const footerGroup = () => {
        if (edoc.g_timb?.i_ti_de !== 7) {
            return (
                <ColumnGroup>
                    <Row>
                        <Column footer="Subtotal: " colSpan={4} />
                        <Column footer={valTotExentas} />
                        <Column footer={valTot5IVA} />
                        <Column footer={valTot10IVA} />
                    </Row>
                    <Row>
                        <Column footer="Total de la operación: " colSpan={6} />
                        <Column footer={valTotGralOpe} />
                    </Row>
                    <Row>
                        <Column footer="Liquidación IVA: " />
                        <Column footer="(5%)" />
                        <Column footer={valLiqIVA5} />
                        <Column footer="(10%)" />
                        <Column footer={valLiqIVA10} />
                        <Column footer="Total IVA" colSpan={1} />
                        <Column footer={valTotIVA} />
                    </Row>
                </ColumnGroup>
            );
        }
        return null;
    };

    const retrieveEventDate = (event) => {
        // eslint-disable-next-line default-case
        switch (event?.eventType) {
            case 'Open':
                return formatDate(event?.open?.timestamp);
            case 'Delivery':
                return formatDate(event?.delivery?.timestamp);
            case 'Bounce':
                return formatDate(event?.bounce?.timestamp);
            case 'Complaint':
                return formatDate(event?.complaint?.timestamp);
        }
    };

    const createEmailEvents = () => {
        return emailEvents?.map((event) => (
            <>
                <Divider align="center"></Divider>
                <div className="col-1">Evento:</div>
                <div className="col-11">{isLoadingEmailEvents ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{event?.eventType}</span>}</div>
                <div className="col-1">Fecha:</div>
                <div className="col-11">{isLoadingEmailEvents ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{retrieveEventDate(event)}</span>}</div>
                {event.eventType === 'Bounce' && (
                    <>
                        <div className="col-1">Razón:</div>
                        <div className="col-11">{isLoadingEmailEvents ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{event?.bounce?.reason}</span>}</div>
                    </>
                )}
                {event.eventType === 'Complaint' && (
                    <>
                        <div className="col-1">Razón:</div>
                        <div className="col-11">{isLoadingEmailEvents ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{event?.bounce?.complaintFeedbackType}</span>}</div>
                    </>
                )}
            </>
        ));
    };

    const startContent = (
        <React.Fragment>
            <Button type="button" icon="pi pi-arrow-left" className="mr-2" tooltip="Volver" onClick={() => navigate('/administracion/documentos')} />
        </React.Fragment>
    );

    const OnSendEmailSuccess = (response) => {
        toast.current.show({
            severity: 'success',
            summary: 'Operación realizada',
            detail: `Documento electrónico enviado`,
            sticky: true
        });
    };

    const OnSendEmailError = (error) => {
        if (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Operación no realizada',
                detail: error.response.data.error,
                sticky: true
            });
        }
    };

    const OnCancelSuccess = (response) => {
        toast.current.show({
            severity: 'success',
            summary: 'Operación realizada',
            detail: `Documento electrónico cancelado`,
            sticky: true
        });
        loadEdoc();
    };

    const OnCancelError = (error) => {
        if (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Operación no realizada',
                detail: error.response.data.error,
                sticky: true
            });
        }
    };

    const onVoidSuccess = (response) => {
        toast.current.show({
            severity: 'success',
            summary: 'Operación realizada',
            detail: `Documento electrónico anulado`,
            sticky: true
        });
        loadEdoc();
    };

    const OnVoidError = (error) => {
        if (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Operación no realizada',
                detail: error.response.data.error,
                sticky: true
            });
        }
    };

    const endContent = () => {
        if (isloading) {
            return <Skeleton height="3rem" width="15rem" className="mb-2"></Skeleton>;
        }

        return (
            <div className="flex justify-content-start">
                <DescargarKuDE edoc={edoc} className="mr-2" />
                {role === 'root' && <XMLInspector edoc={edoc} className="mr-2" />}
                {role === 'root' && <DescargarXML edoc={edoc} className="mr-2" />}
                {edoc?.estado === 'APROBADO' && <EnviarAssets onSuccess={OnSendEmailSuccess} onError={OnSendEmailError} edoc={edoc} className="mr-2" />}
                {edoc?.estado === 'APROBADO' && <CancelarDocumentosElectronicos onSuccess={OnCancelSuccess} onError={OnCancelError} className="mr-2 p-button-danger" edoc={edoc} />}
                {(edoc?.estado === 'RECHAZADO' || edoc?.estado === 'EN_PROCESO') && <AnularDocumentoElectronico onSuccess={onVoidSuccess} onError={OnVoidError} className="mr-2 p-button-danger" edoc={edoc} />}
            </div>
        );
    };

    return (
        <div className="layout-invoice-page">
            <div className="grid layout-invoice-content">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <ConfirmDialog />
                        <Toolbar start={startContent} end={endContent}></Toolbar>
                        <div className="p-3">
                            <div className="col-12 md:col-12">
                                <div className="flex align-items-left justify-content-left">{isloading ? <Skeleton height="2rem" width="13rem" className="mb-2"></Skeleton> : <h5>{deType}</h5>}</div>
                                <Divider></Divider>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-1 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Estado</span>
                                            {isloading ? <Skeleton height="1.3rem" width="5rem" className="mb-2"></Skeleton> : <span>{edoc?.estado}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-5 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Código de control (CDC)</span>
                                            {isloading ? <Skeleton height="1.3rem" width="25rem" className="mb-2"></Skeleton> : <span>{edoc?.id}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-4 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Razón social</span>
                                            {isloading ? <Skeleton height="1.3rem" width="25rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_emis?.d_nom_emi}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-1 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">RUC</span>
                                            {isloading ? <Skeleton height="1.3rem" width="8rem" className="mb-2"></Skeleton> : <span>{marshallRUC()}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Nro. Timbrado</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_timb?.d_num_tim}</span>}
                                        </div>
                                    </div>

                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Inicio de vigencia</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_timb?.d_fe_ini_t}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Nro. Documento</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{marshallEdocNumber()}</span>}
                                        </div>
                                    </div>
                                    {edoc?.g_timb?.i_ti_de === 1 && (
                                        <div className="field col-12 md:col-2 top-spacer">
                                            <div className="flex flex-column gap-2">
                                                <span className="font-bold">Tipo de operación:</span>
                                                {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_ope_com?.d_des_trip_tra}</span>}
                                            </div>
                                        </div>
                                    )}

                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Fecha y hora de emisión</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{formatIssueDate()}</span>}
                                        </div>
                                    </div>
                                    {edoc?.g_timb?.i_ti_de === 1 && (
                                        <div className="field col-12 md:col-2 top-spacer">
                                            <div className="flex flex-column gap-2">
                                                <span className="font-bold">Condición de venta</span>
                                                {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_d_tip_de?.g_cam_cond?.d_d_cond_ope}</span>}
                                            </div>
                                        </div>
                                    )}

                                    {edoc?.g_timb?.i_ti_de === 1 && edoc?.g_d_tip_de?.g_cam_cond.i_cond_ope === 2 && (
                                        <div className="field col-12 md:col-1 top-spacer">
                                            <div className="flex flex-column gap-2">
                                                <span className="font-bold">Plazo</span>
                                                {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_d_tip_de?.g_cam_cond?.g_pag_cred?.d_plazo_cre}</span>}
                                            </div>
                                        </div>
                                    )}
                                    <div className="field col-12 md:col-4 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Dirección</span>
                                            {isloading ? <Skeleton height="1.3rem" width="20rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_emis?.d_dir_emi}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12" style={{ marginTop: '12px' }}>
                                <div className="flex align-items-left justify-content-left">{isloading ? <Skeleton height="2rem" width="13rem" className="mb-2"></Skeleton> : <h5>Datos del receptor</h5>}</div>
                                <Divider></Divider>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">RUC/Nro. Documento</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{formatDestRUC()}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-3 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Razón social</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_dat_rec?.d_nom_rec}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-3 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">E-mail</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_dat_rec?.d_email_rec}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-2 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Dirección</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_dat_rec?.d_dir_rec}</span>}
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-1 top-spacer">
                                        <div className="flex flex-column gap-2">
                                            <span className="font-bold">Teléfono</span>
                                            {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span>{edoc?.g_dat_gral_ope?.g_dat_rec?.d_tel_rec}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 md:col-12" style={{ marginTop: '12px' }}>
                                <div className="flex align-items-left justify-content-left">{isloading ? <Skeleton height="2rem" width="13rem" className="mb-2"></Skeleton> : <h5>Items de la operación</h5>}</div>
                                <Divider></Divider>
                                {/*<div className="p-fluid formgrid grid">*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">Código</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-4 top-spacer">*/}
                                {/*        <span className="font-bold">Descripción</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">Cantidad</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">Precio unitario</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">Exentas</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">5%</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-1 top-spacer">*/}
                                {/*        <span className="font-bold">10%</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="field col-12 md:col-12 top-spacer">{renderLineItems()}</div>*/}
                                {/*</div>*/}
                                <DataTable value={lineItems} headerColumnGroup={headerGroup} footerColumnGroup={footerGroup()}>
                                    <Column header="Cod" field="d_cod_int" />
                                    <Column header="Descripción" field="d_des_pro_ser" />
                                    {/*<Column header="Unidad de medida" field="d_des_uni_med" />*/}
                                    <Column header="Cantidad" field="d_cant_pro_ser" />
                                    {edoc.g_timb?.i_ti_de !== 7 && <Column header="Precio unitario" body={unitPriceVal} />}
                                    {edoc.g_timb?.i_ti_de !== 7 && <Column header="Exentas" body={exentasVal} />}
                                    {edoc.g_timb?.i_ti_de !== 7 && <Column header="5%" body={val5} />}
                                    {edoc.g_timb?.i_ti_de !== 7 && <Column header="10%" body={val10} />}
                                </DataTable>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="grid">
                                    {edoc?.sifen_ticket && (
                                        <div className="col-12 md:col-6">
                                            <Panel header="Detalle Lote">
                                                <div className="grid text-right">
                                                    <Divider align="center"></Divider>
                                                    <div className="col-1">Fecha:</div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{formatDate(edoc?.sifen_ticket?.d_fec_proc)}</span>}</div>

                                                    <div className="col-1">
                                                        <span>Número:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.sifen_ticket?.d_prot_cons_lote}</span>}</div>

                                                    <div className="col-1">
                                                        <span>Respuesta:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.sifen_ticket?.d_msg_res}</span>}</div>
                                                </div>
                                            </Panel>
                                        </div>
                                    )}

                                    <div className="col-12 md:col-6">
                                        <Panel header="Respuesta SIFEN">
                                            <div className="grid text-right">
                                                <Divider align="center"></Divider>
                                                <div className="col-1">Fecha:</div>
                                                <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{formatDate(edoc?.respuesta_sifen?.d_fec_proc)}</span>}</div>

                                                <div className="col-1">
                                                    <span>Estado:</span>
                                                </div>
                                                <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.respuesta_sifen?.estado}</span>}</div>

                                                <div className="col-1">
                                                    <span>Detalle:</span>
                                                </div>
                                                <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{msgSIFENResponse()}</span>}</div>
                                            </div>
                                        </Panel>
                                    </div>

                                    {edoc?.evento_cancelacion && (
                                        <div className="col-12 md:col-6">
                                            <Panel header="Evento cancelación">
                                                <div className="grid text-right">
                                                    <Divider align="center"></Divider>
                                                    <div className="col-1">Fecha:</div>
                                                    <div className="col-11">
                                                        {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{formatDate(edoc?.evento_cancelacion?.fecha_proceso)}</span>}
                                                    </div>

                                                    <div className="col-1">
                                                        <span>Estado:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_cancelacion?.estado}</span>}</div>

                                                    <div className="col-1">
                                                        <span>Motivo:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_cancelacion?.motivo}</span>}</div>

                                                    <div className="col-1">
                                                        <span>Respuesta:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_cancelacion?.msg_respuesta}</span>}</div>
                                                </div>
                                            </Panel>
                                        </div>
                                    )}

                                    {edoc?.evento_nominacion && (
                                        <div className="col-12 md:col-6">
                                            <Panel header="Evento de nominación">
                                                <div className="grid text-right">
                                                    <Divider align="center"></Divider>
                                                    <div className="col-1">Fecha:</div>
                                                    <div className="col-11">
                                                        {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{formatDate(edoc?.evento_nominacion?.fecha_proceso)}</span>}
                                                    </div>
                                                    <div className="col-1">
                                                        <span>Estado:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_nominacion?.estado}</span>}</div>
                                                    <div className="col-1">
                                                        <span>Motivo:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_nominacion?.motivo}</span>}</div>
                                                    <div className="col-1">
                                                        <span>Respuesta:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_nominacion?.msg_respuesta}</span>}</div>
                                                </div>
                                            </Panel>
                                        </div>
                                    )}

                                    {edoc?.evento_inutilizacion && (
                                        <div className="col-12 md:col-6">
                                            <Panel header="Evento de inutilización">
                                                <div className="grid text-right">
                                                    <Divider align="center"></Divider>
                                                    <div className="col-1">Fecha:</div>
                                                    <div className="col-11">
                                                        {isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{formatDate(edoc?.evento_inutilizacion?.fecha_proceso)}</span>}
                                                    </div>
                                                    <div className="col-1">
                                                        <span>Estado:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_inutilizacion?.estado}</span>}</div>
                                                    <div className="col-1">
                                                        <span>Motivo:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_inutilizacion?.motivo}</span>}</div>
                                                    <div className="col-1">
                                                        <span>Respuesta:</span>
                                                    </div>
                                                    <div className="col-11">{isloading ? <Skeleton height="1.3rem" width="10rem" className="mb-2"></Skeleton> : <span className="pink-color">{edoc?.evento_inutilizacion?.msg_respuesta}</span>}</div>
                                                </div>
                                            </Panel>
                                        </div>
                                    )}

                                    {!isEmpty(emailEvents) && (
                                        <div className="col-12 md:col-6">
                                            <Panel header="Seguimiento de correo electrónico">
                                                <ScrollPanel style={{ width: '100%', height: '200px' }}>
                                                    <div className="grid text-right">{createEmailEvents()}</div>
                                                </ScrollPanel>
                                            </Panel>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DetalleDocumentoElectronico;
