import React, { useMemo, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useHttpClient } from '../../hooks/useHttpClient';
import * as Yup from 'yup';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import Timbrado from './Timbrado';
import SeleccionarDepartamento from '../../components/SeleccionarDepartamento';
import SeleccionarDistrito from '../../components/SeleccionarDistrito';
import SeleccionarLocalidad from '../../components/SeleccionarLocalidad';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';
import { FloatLabel } from 'primereact/floatlabel';

const CrearEmisor = () => {
    const [edit, setEdit] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dropdownItems = [
        { name: 'Persona Física', code: 1 },
        { name: 'Persona Jurídica', code: 2 }
    ];

    const httpClient = useHttpClient();

    const [searchParams] = useSearchParams();
    const issuerID = searchParams.get('ruc');
    const navigate = useNavigate();

    const toast = useRef(null);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Campo requerido'),
        ruc: Yup.string().required('Campo requerido'),
        razon_social: Yup.string().required('Campo requerido'),
        direccion: Yup.string().optional(),
        nro_casa: Yup.number(),
        idscs: Yup.string().optional(),
        scs: Yup.string().optional(),
        tipo_contribuyente: Yup.object({
            name: Yup.string(),
            code: Yup.number()
        }),
        codigo_actividad: Yup.string().optional(),
        desc_actividad_emisor: Yup.string().optional(),
        administrar_timbrado: Yup.boolean().default(false),
        departamento: Yup.object({
            codigo: Yup.number(),
            nombre: Yup.string()
        }),
        distrito: Yup.object({
            codigo: Yup.number(),
            nombre: Yup.string()
        }),
        localidad: Yup.object({
            codigo: Yup.number(),
            nombre: Yup.string()
        }),
        telefono: Yup.string().required('Campo requerido'),
        dominio: Yup.string().required('Campo requerido'),
        email_from: Yup.string().email('Debe ser un e-mail válido').required('Campo requerido'),
        soporta_redondeo: Yup.boolean().default(false),
        modelo_aprobacion_posterior: Yup.boolean().default(true),
        imputacion_ventas_rg90: Yup.boolean().default(false),
        email_configuration_set: Yup.string().optional().nullable()
    });

    const onSubmit = (values) => {
        setIsSubmitting(true);
        const url = edit ? process.env.REACT_APP_BACKEND_URL + `admin/emisor/actualizar` : process.env.REACT_APP_BACKEND_URL + `admin/emisor`;
        const issuer = {
            ...values,
            tipo_contribuyente: values.tipo_contribuyente.code
        };
        httpClient
            .post(url, issuer)
            .then(() => {
                setIsSubmitting(false);
                navigate('/administracion/emisores');
            })
            .catch((error) => {
                setIsSubmitting(false);
                toast.current.show({ severity: 'error', summary: 'Error al crear el emisor', detail: error.response.data.error, life: 3000 });
            });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            ruc: '',
            razon_social: '',
            direccion: '',
            nro_casa: 0,
            idscs: '',
            scs: '',
            tipo_contribuyente: dropdownItems[0],
            codigo_actividad: '',
            desc_actividad_emisor: '',
            departamento: {
                codigo: 0,
                nombre: ''
            },
            distrito: {
                codigo: 0,
                nombre: ''
            },
            localidad: {
                codigo: 0,
                nombre: ''
            },
            telefono: '',
            dominio: '',
            email_from: '',
            email_configuration_set: ''
        },
        validationSchema,
        onSubmit
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const titlePage = useMemo(() => {
        if (issuerID) {
            setEdit(true);
            setisLoading(true);
            const url = `admin/emisor/${issuerID}`;
            httpClient
                .get(url)
                .then((response) => {
                    formik.setFieldValue('email', response.data.email);
                    formik.setFieldValue('ruc', response.data.ruc);
                    formik.setFieldValue('razon_social', response.data.razon_social);
                    formik.setFieldValue('direccion', response.data.direccion);
                    formik.setFieldValue('idscs', response.data.idscs);
                    formik.setFieldValue('scs', response.data.scs);
                    formik.setFieldValue('codigo_actividad', response.data.codigo_actividad);
                    formik.setFieldValue('desc_actividad_emisor', response.data.desc_actividad_emisor);
                    formik.setFieldValue('nro_casa', response.data.nro_casa);
                    formik.setFieldValue('telefono', response.data.telefono);
                    formik.setFieldValue('dominio', response.data.dominio);
                    formik.setFieldValue('email_from', response.data.email_from);
                    formik.setFieldValue('email_configuration_set', response.data.email_configuration_set);
                    formik.setFieldValue('administrar_timbrado', response.data.administrar_timbrado);
                    formik.setFieldValue('soporta_redondeo', response.data.soporta_redondeo);
                    formik.setFieldValue('modelo_aprobacion_posterior', response.data.modelo_aprobacion_posterior);
                    formik.setFieldValue('imputacion_ventas_rg_90', response.data.imputacion_ventas_rg_90);

                    let tipoContribuyente = 'Persona Física';

                    if (response.data.tipo_contribuyente === 2) tipoContribuyente = 'Persona Jurídica';

                    formik.setFieldValue('tipo_contribuyente', { name: tipoContribuyente, code: response.data.tipo_contribuyente });

                    if (response.data.departamento) {
                        formik.setFieldValue('departamento', response.data.departamento);
                    }

                    if (response.data.distrito) {
                        formik.setFieldValue('distrito', response.data.distrito);
                    }

                    if (response.data.localidad) {
                        formik.setFieldValue('localidad', response.data.localidad);
                    }

                    setisLoading(false);
                })
                .catch((error) => {
                    setisLoading(false);
                });
            return 'Datos del emisor';
        }
        setEdit(false);
        setisLoading(false);
        return 'Datos del emisor';
        // eslint-disable-next-line
    }, [issuerID]);

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <div className="col-12">
                        <div className="card">
                            <h5>{titlePage}</h5>
                            <Divider></Divider>
                            <Toast ref={toast} />
                            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="email" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="email"
                                                    type="text"
                                                    value={formik.values.email}
                                                    onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('email', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('email') })}
                                                />
                                                <label htmlFor="email">
                                                    E-mail <span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('email')}
                                            </FloatLabel>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="ruc" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="ruc"
                                                    type="text"
                                                    value={formik.values.ruc}
                                                    onChange={(e) => formik.setFieldValue('ruc', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('ruc', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('ruc') })}
                                                />
                                                <label htmlFor="ruc">
                                                    RUC <span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('ruc')}
                                            </FloatLabel>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        {isLoading ? (
                                            <Skeleton id="razonSocial" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="razonSocial"
                                                    type="text"
                                                    value={formik.values.razon_social}
                                                    onChange={(e) => formik.setFieldValue('razon_social', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('razon_social', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('razon_social') })}
                                                />
                                                <label htmlFor="razonSocial">
                                                    Razón Social <span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('razon_social')}
                                            </FloatLabel>
                                        )}
                                    </div>
                                    {isLoading ? (
                                        <div className="field col-12 md:col-6">
                                            <Skeleton id="direccion" height="3rem" className="mb-2"></Skeleton>
                                        </div>
                                    ) : (
                                        <div className="field col-12 md:col-6">
                                            <span className="p-float-label">
                                                <InputTextarea
                                                    id="direccion"
                                                    rows="1"
                                                    value={formik.values.direccion}
                                                    onChange={(e) => formik.setFieldValue('direccion', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('direccion', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('direccion') })}
                                                />
                                                <label htmlFor="direccion">Dirección</label>
                                                {getFormErrorMessage('direccion')}
                                            </span>
                                        </div>
                                    )}
                                    <div className="field col-12 md:col-2">
                                        {isLoading ? (
                                            <Skeleton id="nroCasa" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputNumber
                                                    id="nroCasa"
                                                    value={formik.values.nro_casa}
                                                    onChange={(e) => formik.setFieldValue('nro_casa', e.value)}
                                                    onBlur={() => formik.setFieldTouched('nro_casa', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('nro_casa') })}
                                                />
                                                <label htmlFor="nroCasa">Nro. Casa</label>
                                                {getFormErrorMessage('nro_casa')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="telefono" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="telefono"
                                                    value={formik.values.telefono}
                                                    onChange={(e) => formik.setFieldValue('telefono', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('telefono', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('telefono') })}
                                                />
                                                <label htmlFor="telefono">
                                                    Telefono <span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('telefono')}
                                            </FloatLabel>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="idscs" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputText
                                                    id="idscs"
                                                    type="text"
                                                    value={formik.values.idscs}
                                                    onChange={(e) => formik.setFieldValue('idscs', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('idscs', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('idscs') })}
                                                />
                                                <label htmlFor="idscs">ID SCS</label>
                                                {getFormErrorMessage('idscs')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="scs" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputText
                                                    id="scs"
                                                    type="text"
                                                    value={formik.values.scs}
                                                    onChange={(e) => formik.setFieldValue('scs', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('scs', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('scs') })}
                                                />
                                                <label htmlFor="scs">SCS</label>
                                                {getFormErrorMessage('scs')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="dominio" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="dominio"
                                                    type="text"
                                                    value={formik.values.dominio}
                                                    onChange={(e) => formik.setFieldValue('dominio', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('dominio', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('dominio') })}
                                                />
                                                <label htmlFor="dominio">
                                                    Dominio<span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('dominio')}
                                            </FloatLabel>
                                        )}
                                    </div>

                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="emailfrom" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <FloatLabel>
                                                <InputText
                                                    id="emailfrom"
                                                    type="text"
                                                    value={formik.values.email_from}
                                                    onChange={(e) => formik.setFieldValue('email_from', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('email_from', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('email_from') })}
                                                />
                                                <label htmlFor="emailfrom">
                                                    Email-from<span className="pink-color"> *</span>
                                                </label>
                                                {getFormErrorMessage('email_from')}
                                            </FloatLabel>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="emailConfSet" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputText
                                                    id="emailConfSet"
                                                    type="text"
                                                    value={formik.values.email_configuration_set}
                                                    onChange={(e) => formik.setFieldValue('email_configuration_set', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('email_configuration_set', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('email_configuration_set') })}
                                                />
                                                <label htmlFor="emailConfSet">Email configuración SES</label>
                                                {getFormErrorMessage('email_configuration_set')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="tipoContribuyente" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <Dropdown
                                                    id="tipoContribuyente"
                                                    value={formik.values.tipo_contribuyente}
                                                    onChange={(e) => formik.setFieldValue('tipo_contribuyente', e.value)}
                                                    onBlur={() => formik.setFieldTouched('tipo_contribuyente', true)}
                                                    options={dropdownItems}
                                                    optionLabel="name"
                                                    placeholder="Seleccione el tipo de contribuyente"
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('tipo_contribuyente') })}
                                                />
                                                <label htmlFor="tipoContribuyente">Tipo de contribuyente</label>
                                                {getFormErrorMessage('tipo_contribuyente')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-3">
                                        {isLoading ? (
                                            <Skeleton id="codEmisor" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputText
                                                    id="codEmisor"
                                                    type="text"
                                                    value={formik.values.codigo_actividad}
                                                    onChange={(e) => formik.setFieldValue('codigo_actividad', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('codigo_actividad', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('codigo_actividad') })}
                                                />
                                                <label htmlFor="codEmisor">Código de la actividad del emisor</label>
                                                {getFormErrorMessage('codigo_actividad')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        {isLoading ? (
                                            <Skeleton id="descEmisor" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <InputText
                                                    id="descEmisor"
                                                    type="text"
                                                    value={formik.values.desc_actividad_emisor}
                                                    onChange={(e) => formik.setFieldValue('desc_actividad_emisor', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('desc_actividad_emisor', true)}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('desc_actividad_emisor') })}
                                                />
                                                <label htmlFor="descEmisor">Descripción de la actividad</label>
                                                {getFormErrorMessage('desc_actividad_emisor')}
                                            </span>
                                        )}
                                    </div>

                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="departamento" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <SeleccionarDepartamento
                                                    id="departamento"
                                                    value={formik.values.departamento}
                                                    onChange={(e) => formik.setFieldValue('departamento', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('departamento', true)}
                                                    skeletonHeigh={'3rem'}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('departamento') })}
                                                />
                                                <label htmlFor="departamento">Departamento</label>
                                                {getFormErrorMessage('departamento')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="distrito" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <SeleccionarDistrito
                                                    id="distrito"
                                                    departamento={formik.values.departamento.codigo}
                                                    value={formik.values.distrito}
                                                    onChange={(e) => formik.setFieldValue('distrito', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('distrito', true)}
                                                    skeletonHeigh={'3rem'}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('distrito') })}
                                                />
                                                <label htmlFor="distrito">Distrito</label>
                                                {getFormErrorMessage('distrito')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        {isLoading ? (
                                            <Skeleton id="localidad" height="3rem" className="mb-2"></Skeleton>
                                        ) : (
                                            <span className="p-float-label">
                                                <SeleccionarLocalidad
                                                    id="localidad"
                                                    distrito={formik.values.distrito.codigo}
                                                    value={formik.values.localidad}
                                                    onChange={(e) => formik.setFieldValue('localidad', e.target.value)}
                                                    onBlur={() => formik.setFieldTouched('localidad', true)}
                                                    skeletonHeigh={'3rem'}
                                                    className={classNames({ 'p-invalid': isFormFieldInvalid('localidad') })}
                                                />
                                                <label htmlFor="localidad">Localidad</label>
                                                {getFormErrorMessage('localidad')}
                                            </span>
                                        )}
                                    </div>

                                    <h5>Configuraciones del sistema</h5>
                                    <Divider></Divider>
                                    {isLoading ? (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="redondeo">Soporta redondeo (Resolución 347/14 SEDECO)</label>
                                            <Skeleton id="redondeo" height="3rem" className="mb-2"></Skeleton>
                                        </div>
                                    ) : (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="redondeo">Soporta redondeo (Resolución 347/14 SEDECO)</label>
                                            <InputSwitch checked={formik.values.soporta_redondeo} onChange={(e) => formik.setFieldValue('soporta_redondeo', e.value)} />
                                            {getFormErrorMessage('soporta_redondeo')}
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <div className="field col-12 md:col-1">
                                            <label htmlFor="adminTimb">Administrar timbrado</label>
                                            <Skeleton id="adminTimb" height="3rem" className="mb-2"></Skeleton>
                                        </div>
                                    ) : (
                                        <div className="field col-12 md:col-1">
                                            <label htmlFor="adminTimb">Administrar timbrado</label>
                                            <InputSwitch checked={formik.values.administrar_timbrado} onChange={(e) => formik.setFieldValue('administrar_timbrado', e.value)} />
                                            {getFormErrorMessage('administrar_timbrado')}
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="modAprobPost">Modelo de aprobación posterior</label>
                                            <Skeleton id="modAprobPost" height="3rem" className="mb-2"></Skeleton>
                                        </div>
                                    ) : (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="modAprobPost">Modelo de aprobación posterior</label>
                                            <InputSwitch checked={formik.values.modelo_aprobacion_posterior} onChange={(e) => formik.setFieldValue('modelo_aprobacion_posterior', e.value)} />
                                            {getFormErrorMessage('modelo_aprobacion_posterior')}
                                        </div>
                                    )}
                                    {isLoading ? (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="imputacionVentasRG90">Campos para imputacion de ventas RG90</label>
                                            <Skeleton id="imputacionVentasRG90" height="3rem" className="mb-2"></Skeleton>
                                        </div>
                                    ) : (
                                        <div className="field col-12 md:col-2">
                                            <label htmlFor="imputacionVentasRG90">Campos para imputacion de ventas RG90</label>
                                            <InputSwitch checked={formik.values.imputacion_ventas_rg_90} onChange={(e) => formik.setFieldValue('imputacion_ventas_rg_90', e.value)} />
                                            {getFormErrorMessage('imputacion_ventas_rg_90')}
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-wrap justify-content-end gap-2">
                                    <Button type="button" className=" p-button-danger field col-12 md:col-1" onClick={() => navigate('/administracion/emisores')} label="Cancelar" />
                                    <Button type="submit" className="field col-12 md:col-1" loading={isSubmitting} label="Guardar" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {formik.values.administrar_timbrado && <Timbrado issuer={issuerID} />}
        </React.Fragment>
    );
};

export default CrearEmisor;
