import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { isEmpty } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';
import { LISTA_FORMA_PROC_PAGO } from './constants/condicion_operacion';

const GCamCond = (props) => {
    const listaCondicionOp = [
        {
            i_cond_ope: 1,
            d_d_cond_ope: 'Contado'
        },
        {
            i_cond_ope: 2,
            d_d_cond_ope: 'Crédito'
        }
    ];

    const listaCondicionCredito = [
        {
            i_cond_cred: 1,
            dd_cond_cred: 'Plazo'
        }
    ];

    const listaTipoPago = [
        {
            i_ti_pago: 1,
            d_des_ti_pag: 'Efectivo'
        },
        {
            i_ti_pago: 2,
            d_des_ti_pag: 'Cheque'
        },
        {
            i_ti_pago: 3,
            d_des_ti_pag: 'Tarjeta de crédito'
        },
        {
            i_ti_pago: 4,
            d_des_ti_pag: 'Tarjeta de débito'
        },
        {
            i_ti_pago: 5,
            d_des_ti_pag: 'Transferencia'
        },
        {
            i_ti_pago: 6,
            d_des_ti_pag: 'Giro'
        },
        {
            i_ti_pago: 7,
            d_des_ti_pag: 'Billetera electrónica'
        },
        {
            i_ti_pago: 8,
            d_des_ti_pag: 'Tarjeta empresarial'
        },
        {
            i_ti_pago: 9,
            d_des_ti_pag: 'Vale'
        },
        {
            i_ti_pago: 10,
            d_des_ti_pag: 'Retención'
        },
        {
            i_ti_pago: 11,
            d_des_ti_pag: 'Pago por anticipo'
        },
        {
            i_ti_pago: 12,
            d_des_ti_pag: 'Valor fiscal'
        },
        {
            i_ti_pago: 13,
            d_des_ti_pag: 'Valor comercial'
        },
        {
            i_ti_pago: 14,
            d_des_ti_pag: 'Compensación'
        },
        {
            i_ti_pago: 15,
            d_des_ti_pag: 'Permuta'
        },
        {
            i_ti_pago: 16,
            d_des_ti_pag: 'Pago bancario'
        },
        {
            i_ti_pago: 17,
            d_des_ti_pag: 'Pago Móvil'
        },
        {
            i_ti_pago: 18,
            d_des_ti_pag: 'Donación'
        },
        {
            i_ti_pago: 19,
            d_des_ti_pag: 'Promoción'
        },
        {
            i_ti_pago: 20,
            d_des_ti_pag: 'Consumo Interno'
        },
        {
            i_ti_pago: 21,
            d_des_ti_pag: 'Pago Electrónico'
        }
        // {
        //     i_ti_pago: 99,
        //     d_des_ti_pag: 'Otro' No soportado hasta el momento
        // }
    ];
    const listaMonedas = [
        {
            code: 'PYG',
            name: 'Guarani'
        },
        {
            code: 'USD',
            name: 'US Dollar'
        }
    ];

    const listaTarjetas = [
        {
            i_den_tarj: 1,
            d_des_den_tarj: 'Visa'
        },
        {
            i_den_tarj: 2,
            d_des_den_tarj: 'Mastercard'
        },
        {
            i_den_tarj: 3,
            d_des_den_tarj: 'American Express'
        },
        {
            i_den_tarj: 4,
            d_des_den_tarj: 'Maestro'
        },
        {
            i_den_tarj: 5,
            d_des_den_tarj: 'Panal'
        },
        {
            i_den_tarj: 6,
            d_des_den_tarj: 'Cabal'
        }
    ];

    return (
        <div className="p-fluid formgrid grid">
            <Divider align="center">
                <b>Campos que describen la condición de la operación</b>
            </Divider>
            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <Dropdown
                        id="condOp"
                        value={props.formik.values.cond_ope}
                        onChange={(e) => props.formik.setFieldValue('cond_ope', e.value)}
                        onBlur={() => props.formik.setFieldTouched('cond_ope', true)}
                        options={listaCondicionOp}
                        optionLabel="d_d_cond_ope"
                        placeholder="Seleccione la condición de la operación"
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('cond_ope') })}
                    />
                    <label htmlFor="condOp">Condición de la operación</label>
                    {props.getFormErrorMessage('cond_ope')}
                </FloatLabel>
            </div>
            {props.formik.values.cond_ope?.i_cond_ope === 2 && (
                <>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="condCred"
                                value={props.formik.values.cond_cred}
                                onChange={(e) => props.formik.setFieldValue('cond_cred', e.value)}
                                onBlur={() => props.formik.setFieldTouched('cond_cred', true)}
                                options={listaCondicionCredito}
                                optionLabel="dd_cond_cred"
                                placeholder="Seleccione la condición a de la operación a crédito"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('cond_cred') })}
                            />
                            <label htmlFor="condCred">Condición de la operación a crédito</label>
                            {props.getFormErrorMessage('cond_cred')}
                        </FloatLabel>
                    </div>
                    {props.formik.values.cond_cred?.i_cond_cred === 1 && (
                        <div className="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText
                                    id="plazoCredito"
                                    type="text"
                                    value={props.formik.values.d_plazo_cre}
                                    onChange={(e) => props.formik.setFieldValue('d_plazo_cre', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_plazo_cre', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_plazo_cre') })}
                                />
                                <label htmlFor="plazoCredito">Plazo del crédito</label>
                                {props.getFormErrorMessage('d_plazo_cre')}
                            </FloatLabel>
                        </div>
                    )}
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <InputNumber
                                id="tipoCambioOp"
                                type="text"
                                locale="es-PY"
                                value={props.formik.values.d_mon_ent}
                                onChange={(e) => props.formik.setFieldValue('d_mon_ent', e.value)}
                                onBlur={() => props.formik.setFieldTouched('d_mon_ent', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_mon_ent') })}
                            />
                            <label htmlFor="tipoCambioOp">Monto de la entrega inicial</label>
                            {props.getFormErrorMessage('d_mon_ent')}
                        </FloatLabel>
                    </div>
                </>
            )}
            {(props.formik.values.cond_ope?.i_cond_ope === 1 || props.formik.values.d_mon_ent > 0) && (
                <>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="tipoPago"
                                value={props.formik.values.tipo_pago}
                                onChange={(e) => props.formik.setFieldValue('tipo_pago', e.value)}
                                onBlur={() => props.formik.setFieldTouched('tipo_pago', true)}
                                options={listaTipoPago}
                                optionLabel="d_des_ti_pag"
                                placeholder="Seleccione el tipo de pago"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_pago') })}
                            />
                            <label htmlFor="tipoPago">Tipo de pago</label>
                            {props.getFormErrorMessage('tipo_pago')}
                        </FloatLabel>
                    </div>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="monedaTipoPago"
                                value={props.formik.values.moneda_tipo_pago}
                                onChange={(e) => props.formik.setFieldValue('moneda_tipo_pago', e.value)}
                                onBlur={() => props.formik.setFieldTouched('moneda_tipo_pago', true)}
                                options={listaMonedas}
                                optionLabel="code"
                                placeholder="Seleccione la moneda"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('moneda_tipo_pago') })}
                            />
                            <label htmlFor="monedaTipoPago">Moneda</label>
                            {props.getFormErrorMessage('moneda_tipo_pago')}
                        </FloatLabel>
                    </div>

                    {!isEmpty(props.formik.values.moneda_tipo_pago?.code) && props.formik.values.moneda_tipo_pago?.code !== 'PYG' && (
                        <div className="field col-12 md:col-4">
                            <FloatLabel>
                                <InputNumber
                                    id="tipoCambioPago"
                                    type="text"
                                    locale="es-PY"
                                    value={props.formik.values.d_ti_cam_ti_pag}
                                    onChange={(e) => props.formik.setFieldValue('d_ti_cam_ti_pag', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_ti_cam_ti_pag', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ti_cam_ti_pag') })}
                                />
                                <label htmlFor="tipoCambioPago">Tipo de cambio por tipo de pago</label>
                                {props.getFormErrorMessage('d_ti_cam_ti_pag')}
                            </FloatLabel>
                        </div>
                    )}
                    {(props.formik.values.tipo_pago?.i_ti_pago === 3 || props.formik.values.tipo_pago?.i_ti_pago === 4) && (
                        <>
                            <div className="field col-12 md:col-4">
                                <FloatLabel>
                                    <Dropdown
                                        id="denomTarj"
                                        value={props.formik.values.iden_tarj}
                                        onChange={(e) => props.formik.setFieldValue('iden_tarj', e.value)}
                                        onBlur={() => props.formik.setFieldTouched('iden_tarj', true)}
                                        options={listaTarjetas}
                                        optionLabel="d_des_den_tarj"
                                        placeholder="Seleccione la denominación de la tarjeta"
                                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('iden_tarj') })}
                                    />
                                    <label htmlFor="denomTarj">Denominación de la tarjeta</label>
                                    {props.getFormErrorMessage('iden_tarj')}
                                </FloatLabel>
                            </div>
                            <div className="field col-12 md:col-4">
                                <FloatLabel>
                                    <Dropdown
                                        id="formaProcPago"
                                        value={props.formik.values.forma_proc_pago}
                                        onChange={(e) => props.formik.setFieldValue('forma_proc_pago', e.value)}
                                        onBlur={() => props.formik.setFieldTouched('forma_proc_pago', true)}
                                        options={LISTA_FORMA_PROC_PAGO}
                                        optionLabel="d_des_for_pra"
                                        placeholder="Seleccione la forma de procesamiento del pago"
                                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('forma_proc_pago') })}
                                    />
                                    <label htmlFor="formaProcPago">Forma de procesamiento de pago</label>
                                    {props.getFormErrorMessage('forma_proc_pago')}
                                </FloatLabel>
                            </div>
                        </>
                    )}
                    {props.formik.values.tipo_pago?.i_ti_pago === 2 && (
                        <>
                            <div className="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputText
                                        id="nroCheque"
                                        type="text"
                                        value={props.formik.values.d_num_cheq}
                                        onChange={(e) => props.formik.setFieldValue('d_num_cheq', e.target.value)}
                                        onBlur={() => props.formik.setFieldTouched('d_num_cheq', true)}
                                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_cheq') })}
                                    />
                                    <label htmlFor="nroCheque">Número de cheque</label>
                                    {props.getFormErrorMessage('d_num_cheq')}
                                </FloatLabel>
                            </div>
                            <div className="field col-12 md:col-4">
                                <FloatLabel>
                                    <InputText
                                        id="bancoEmi"
                                        type="text"
                                        value={props.formik.values.d_b_co_emi}
                                        onChange={(e) => props.formik.setFieldValue('d_b_co_emi', e.target.value)}
                                        onBlur={() => props.formik.setFieldTouched('d_b_co_emi', true)}
                                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_b_co_emi') })}
                                    />
                                    <label htmlFor="bancoEmi">Banco emisor</label>
                                    {props.getFormErrorMessage('d_b_co_emi')}
                                </FloatLabel>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default GCamCond;
